import { render, staticRenderFns } from "./DocConfigListDiscount.vue?vue&type=template&id=06562477&scoped=true&"
import script from "./DocConfigListDiscount.vue?vue&type=script&lang=js&"
export * from "./DocConfigListDiscount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06562477",
  null
  
)

export default component.exports